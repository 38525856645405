import $ from 'jquery'
import {
  PackageLotSelect,
  formatPricing,
} from '../../components/package-single/index'
import { MAY_PROMO_ACTIVE } from './constants'
import { getCookie, setCookie } from './cookie'
import {
  initYardzenLeadCallback,
  initYardzenLeadCallbackNoPromo,
} from './attentive-callback'

const ANONYMOUS_FEATURE_FLAGS = [
  'HOMEPAGE_REDIRECT',
  'BLOG_CTAS',
  'FIRST_TIME_VISITOR_TEST',
  'RETURNING_VISITOR_TEST',
  'ALL_PACKAGES_PRICE_TEST',
  'SHOW_ATTN_MODAL',
  'PROJECT_CONSULTATION',
  'FULL_YARD_NO_LOT_SELECTION',
  'FREE_PROJECT_PLANNER_VS_DESIGN_PROFILE_QUIZ',
  'DESIGN_ON_DEMAND',
  'New_Homepage_Redesign',
  'SPRING_SALE_WEB_PLACEMENTS',
  'New_PLP_Redesign',
  'PREMIUM_PACKAGE_PRICE_TEST',
  'PURCHASE_VS_BUYNOW',
  'PROMO_CODE_FROM_ATTENTIVE',
]
const BASE_URLS = [
  'http://yardzen.localhost',
  'https://yardzenstage.wpengine.com',
  'https://yardzendev.wpengine.com',
  'http://yardzendev.localhost',
  'https://yardzen.com',
]
const ALLOWED_HOMEPAGE_REDIRECT_PATHS = ['/']
const ALLOWED_BLOG_PATHS = ['/yzblog/']
const ALLOWED_CURB_APPEAL_PRICE_TEST_PATHS = ['/curb-appeal', '/packages']
const ALLOWED_FRONT_YARD_PRICE_TEST_PATHS = ['/front-yard', '/packages']

function isCurrentUrlAllowedForABTest(paths, partialMatch = false) {
  const currentUrl = window.location.href

  if (partialMatch) {
    return paths.some((path) => currentUrl.includes(path))
  }

  return BASE_URLS.some((base) =>
    paths.some((path) => currentUrl === base + path)
  )
}

const inLifeStylePkgPage = () => {
  return [
    '/first-time-homeowners-backyard',
    '/pet-lovers-backyard',
    '/kids-paradise-backyard',
    '/retirement-retreat-backyard',
    '/entertainers-backyard',
    '/wellness-backyard',
  ].includes(window.location.pathname)
}

const inLifeStylePkgsLandingPage = () => {
  return window.location.pathname === '/lifestyle-packages'
}

function handleSDKReady(client, featureFlags) {
  client.on(client.Event.SDK_READY, function () {
    const attributes = {
      first_time_visitor: isFirstTimeVisitor(),
    }

    const treatments = client.getTreatments(featureFlags, attributes)

    if (treatments.PROMO_CODE_FROM_ATTENTIVE === 'off') {
      initYardzenLeadCallbackNoPromo()
    } else {
      initYardzenLeadCallback()
    }

    if (
      window.location.pathname === '/packages' &&
      treatments.New_PLP_Redesign === 'on'
    ) {
      window.location.replace('/products')
    }

    if (treatments.PURCHASE_VS_BUYNOW === 'on') {
      if (
        window.location.pathname === '/front-yard' ||
        window.location.pathname === '/back-yard' ||
        window.location.pathname === '/full-yard' ||
        window.location.pathname === '/outdoor-transformation' ||
        window.location.pathname === '/premium'
      ) {
        $('.pkghero__purchase > a').html('Buy Now')
      }
    }

    if (treatments.PREMIUM_PACKAGE_PRICE_TEST === 'on') {
      const mayPromoActive = MAY_PROMO_ACTIVE

      if (
        window.location.pathname === '/premium' ||
        window.location.pathname === '/new-premium'
      ) {
        const standardSelector = document.querySelector(
          'li[data-size="standard"]'
        )
        const largeSelector = document.querySelector('li[data-size="large"]')

        if (!mayPromoActive) {
          standardSelector.setAttribute('data-price', '3795')
          standardSelector.setAttribute('data-size', 'standard_b')

          largeSelector.setAttribute('data-price', '4295')
          largeSelector.setAttribute('data-size', 'large_b')
        } else {
          standardSelector.setAttribute('data-price', '3795')
          standardSelector.setAttribute('data-size', 'standard_b')
          standardSelector.setAttribute('data-discounted-price', '2846.25')

          largeSelector.setAttribute('data-price', '4295')
          largeSelector.setAttribute('data-size', 'large_b')
          largeSelector.setAttribute('data-discounted-price', '3221.25')
        }

        sessionStorage.setItem('lotsize', 'standard_b')
        PackageLotSelect(true)
        if (mayPromoActive) {
          document.querySelector('.js-price > span').innerHTML = formatPricing(
            3795,
            2846.25,
            standardSelector.getAttribute('data-strikethrough-price-color')
          )
        }
      } else if (
        window.location.pathname === '/packages' ||
        window.location.pathname === '/new-packages'
      ) {
        if (!mayPromoActive) {
          document.querySelector(
            '.package--premium > .yz-card > .yz-card__body > .yz-card__subtitle'
          ).innerHTML = 'Starting at $3,795'
        } else {
          document.querySelector(
            '.package--premium > .yz-card > .yz-card__body > .yz-card__subtitle'
          ).innerHTML = 'Starting at <s>$3,795</s> $2,846.25'
        }
      }
    }

    // Remove the spinner once the sdk is ready for all treatments except a page redirect
    // To avoid a flash, we want the spinner to show until redirect occurs
    if (treatments.PROJECT_CONSULTATION === 'on') {
      updateConsultCallLanguage()
    }
    if (
      isCurrentUrlAllowedForABTest(ALLOWED_HOMEPAGE_REDIRECT_PATHS) &&
      treatments.HOMEPAGE_REDIRECT === 'on'
    ) {
      window.location.replace(`${window.location.href}homepage`)
    }

    if (treatments.DESIGN_ON_DEMAND) {
      showOrHideDesignOnDemand(treatments.DESIGN_ON_DEMAND)
    }

    if (treatments.AB_BUILDREBATE_PROMO) {
      handleBuildRebateTest(treatments.AB_BUILDREBATE_PROMO == 'on')
    }

    const promoCodeIsApplied = getCookie('yzpdc') !== ''

    if (treatments.ALL_PACKAGES_PRICE_TEST === 'on' && !promoCodeIsApplied) {
      window.inPTest = true
      if (window.location.pathname === '/packages') {
        unhideDiscountPrices()
      } else if (window.location.pathname === '/front-yard') {
        updatePackagePageFrontYardPrices()
      } else if (window.location.pathname === '/back-yard') {
        updatePackagePageBackYardPrices()
      } else if (window.location.pathname === '/full-yard') {
        updatePackagePageFullYardPrices()
      } else if (window.location.pathname === '/outdoor-transformation') {
        updatePackagePageOutdoorTransformationPrices()
      } else if (window.location.pathname === '/premium') {
        updatePackagePagePremiumPrices()
      } else if (inLifeStylePkgPage()) {
        updatePackagePageLifeStylePrices()
      } else if (inLifeStylePkgsLandingPage()) {
        updateLifestylePkgsLandingPagePrices()
      }
      updatePackagePurchaseUrl()
      unhidePrice()
    } else {
      delete window.inPTest
      unhideNormalPrices()
      unhidePrice()
    }

    if (
      isCurrentUrlAllowedForABTest(ALLOWED_BLOG_PATHS, true) &&
      treatments.BLOG_CTAS === 'on'
    ) {
      showInBodyCTA()
    }

    if (
      window.location.pathname === '/full-yard' &&
      treatments.FULL_YARD_NO_LOT_SELECTION === 'on'
    ) {
      hideLotSizeSelection()
    }

    if (
      window.location.pathname === '/' &&
      treatments.SPRING_SALE_WEB_PLACEMENTS === 'on'
    ) {
      applyWebPlacementsChanges()
    }
  })

  client.on(client.Event.SDK_READY_TIMED_OUT, function () {
    console.error('Error loading Split.io SDK')
    removeLoadingSpinner()
  })
}

function showOrHideDesignOnDemand(treatmentVal) {
  if (!treatmentVal) {
    return
  }

  // Hides or shows the design on demand package option on the packages page
  // ps - I love some good load bearing text and CSS
  const desktopElement = jQuery('section.image-content')
    .find("h2:contains('Design on Demand')")
    .parents('section.image-content')
    .first()
  const mobileElement = jQuery('.cards__card--has-link')
    .find("h3:contains('Design on Demand')")
    .first()
    .parents('.cards__card')
    .first()

  if (treatmentVal === 'on') {
    desktopElement.show()
    mobileElement.show()
  } else {
    desktopElement.hide()
    mobileElement.hide()
  }
}

function hideLotSizeSelection() {
  $('.pkghero__purchase-dropdown').css('display', 'none')
  $('.pkghero__purchase-btn').css('margin-left', '0')
}

function setFirstTimeVisitorDate() {
  const firstTimeVisitDate = getCookie('yzftv')
  if (!firstTimeVisitDate) {
    setCookie('yzftv', new Date(), 365)
  }
}

function isFirstTimeVisitor() {
  const firstTimeVisitDate = new Date(getCookie('yzftv'))
  const today = new Date()
  if (
    firstTimeVisitDate &&
    firstTimeVisitDate.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0)
  ) {
    return true
  }
  return false
}

export function getSessionId() {
  let sessionId = getCookie('yzsa')
  if (!sessionId) {
    try {
      sessionId = window.analytics?.user()?.anonymousId()
    } catch {
      sessionId = 'key'
    }
  }

  return sessionId
}

function initSplitIoCode() {
  setFirstTimeVisitorDate()

  // remove loading spinner after 3 seconds just in case there are any issues with the split.io sdk
  setTimeout(function () {
    removeLoadingSpinner()
  }, 3000)

  // unhide price after 2 seconds just in case there are any issues with the split.io sdk
  setTimeout(function () {
    unhidePrice()
  }, 3000)

  const sessionId = getSessionId()
  const userId = sessionId
  var factory = splitio({
    core: {
      authorizationKey: php_vars.splitioKey,
      key: userId,
      trafficType: 'anonymous', // default traffic type
    },
  })

  // Init sdk on different trafic types using: https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#advanced-instantiate-multiple-sdk-clients
  // anonymous traffic type
  const anonymousUserClient = factory.client()
  handleSDKReady(anonymousUserClient, ANONYMOUS_FEATURE_FLAGS)
}

function removeLoadingSpinner() {
  $('.landing-page-content-wrapper').css('visibility', 'inherit')
  $('.landing-page-loading-spinner-wrapper').css('display', 'none')
}

function showInBodyCTA() {
  const $paragraphs = $('article:eq(0) p')

  if ($paragraphs.length >= 4) {
    $paragraphs.eq(3).after($('#in-body-cta'))
  } else {
    // If there are fewer than 4 paragraphs, append the CTA to the end of the article
    $('article:eq(0)').append($('#in-body-cta'))
  }
  $('#in-body-cta').removeClass('in-body-cta--hidden')
  $('#in-body-cta-sign-up').on('click', function (event) {
    event.preventDefault()
    window.location.assign(`https://yardzen.attn.tv/p/yhA/landing-page`)
  })
}

function updatePackagePageFrontYardPrices() {
  if (isStandardSelected()) {
    $('.js-price > span').html('$955')
  } else {
    $('.js-price > span').html('$1,455')
  }
  $('li[data-size="standard"]').data('price', '955')
  $('li[data-size="large"]').data('price', '1455')
}

function updatePackagePageBackYardPrices() {
  if (isStandardSelected()) {
    $('.js-price > span').html('$1115')
  } else {
    $('.js-price > span').html('$1615')
  }
  $('li[data-size="standard"]').data('price', '1115')
  $('li[data-size="large"]').data('price', '1615')
}

function updatePackagePageFullYardPrices() {
  if (isStandardSelected()) {
    $('.js-price > span').html('$1355')
  } else {
    $('.js-price > span').html('$1855')
  }
  $('li[data-size="standard"]').data('price', '1355')
  $('li[data-size="large"]').data('price', '1855')
}

function updatePackagePageOutdoorTransformationPrices() {
  if (isStandardSelected()) {
    $('.js-price > span').html('$1995')
  } else {
    $('.js-price > span').html('$2495')
  }
  $('li[data-size="standard"]').data('price', '1995')
  $('li[data-size="large"]').data('price', '2495')
}

function updatePackagePagePremiumPrices() {
  if (isStandardSelected()) {
    $('.js-price > span').html('$2635')
  } else {
    $('.js-price > span').html('$3135')
  }
  $('li[data-size="standard"]').data('price', '2635')
  $('li[data-size="large"]').data('price', '3135')
}

function updatePackagePageLifeStylePrices() {
  // Set experiment prices
  if (isStandardSelected()) {
    $('.js-price > span').html('$1115')
  } else {
    $('.js-price > span').html('$1615')
  }

  $('li[data-size="standard"]').data('price', '1115')
  $('li[data-size="large"]').data('price', '1615')
}

function updateLifestylePkgsLandingPagePrices() {
  $('body#lifestyle-packages').find('.cards__card-tag--pill').text('$1115')
}

function updatePackagePurchaseUrl() {
  const href = $('.js-purchase').attr('href')

  if (!href) {
    return
  }

  const url = new URL(href)

  if (isStandardSelected()) {
    url.searchParams.set('lotsize', 'standard_b')
  } else {
    url.searchParams.set('lotsize', 'large_b')
  }

  $('.js-purchase').attr('href', url.toString())

  $('li[data-size="standard"]').data('size', 'standard_b')
  $('li[data-size="large"]').data('size', 'large_b')
}

function isStandardSelected() {
  return $('#lot-size-btn > span').html()?.toLowerCase().includes('standard')
}

function unhidePrice() {
  $('.js-price').css('visibility', 'inherit')
}

function unhideNormalPrices() {
  $('.price--normal').css('display', 'inline')
  $('.price--discounted').css('display', 'none')
  $('body#lifestyle-packages').find('.cards__card-tag--pill').text('$1395')
}

function unhideDiscountPrices() {
  $('.price--normal').css('display', 'none')
  $('.price--discounted').css('display', 'inline')
}

export default function initSplitTesting() {
  window.addEventListener('load', initSplitIoCode)
}

function updateConsultCallLanguage() {
  const newText = 'Book a Free Project Consultation'
  $('a[href*="https://calendly"]').each(function () {
    // Check if the <a> element has a <span> child for the top nav consult call btn
    var spanElement = $(this).find('span.text:first')

    if (spanElement.length) {
      spanElement.text(newText)
    } else {
      $(this).text(newText)
    }
  })
}

function applyWebPlacementsChanges() {
  $('#content > div > div.hero-redux > div > div.hero-redux__content > p').text(
    'Complete your yard project faster and at half the cost of hiring a traditional designer. 20% off all design packages for a limited time.'
  )

  $(
    '#content > div > div.products > div > div.products__grid > div > div.product.product--front-yard > p'
  ).html('Starting at <s>$1195</s> $956')

  $(
    '#content > div > div.products > div > div.products__grid > div > div.product.product--back-yard > p'
  ).html('Starting at <s>$1395</s> $1116')

  $(
    '#content > div > div.products > div > div.products__grid > div > div.product.product--full-yard > p'
  ).html('Starting at <s>$1695</s> $1356')
}
